


































































import { Component, Prop, Vue } from "vue-property-decorator";
import { IStrDetailValue } from "@/Services/Interfaces";
import { globalUpdateDetailService } from "@/Services/GlobalService";
import { CrudType } from "@/Services/enums";
import BaseSubComponent from "./BaseSubComponent.vue";
import ActionComponent from "./ActionComponent.vue";

@Component({ components: { BaseSubComponent, ActionComponent } })
export default class UriComponent extends BaseSubComponent {
  constructor() {
    super();
    this.name = "UrlComponent";


    this.thismodel = <IStrDetailValue>{};
  }

  name: string;

  thismodel: IStrDetailValue;

hasUrlTitel (model: IStrDetailValue)
{
  return model.strvalue &&  model.strvalue.length > 0 && (model.strvalue.indexOf("//") == -1);
}

  onUpdate(model: any) {
    if (this.canUpdate(model)) {
      model.crud = CrudType.updatememovalue;
      globalUpdateDetailService.sendMessage(model);
    }
  }

  data() {
    return {
      thismodel: this.model
    };
  }

  mounted() {
    this.thismodel.memovalue =
      this.thismodel.memovalue == undefined ||
      this.thismodel.memovalue.length == 0
        ? this.thismodel.strvalue
        : this.thismodel.memovalue;
  }
}
